<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="ma-2" outlined color="primary"
        ><slot></slot
      ></v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Выберите время :
      </v-card-title>

      <v-card-text>
        <showDateTimePicker
          :handle-user-select-data="handleUserSelectData"
          :default-value="defaultValue"
          @userSelect="userSelect"
        ></showDateTimePicker>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="dialog = false" depressed color="primary" class="mt-5">
          Запланировать на {{ selectedSlot.time }}
          {{ selectedSlot.data }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const showDateTimePicker = () => import("./showDateTimePicker");
export default {
  name: "showDateTimePickerModal",
  components: {
    showDateTimePicker,
  },
  data() {
    return {
      selectedSlot: {},
      dialog: false,
    };
  },
  props: {
    defaultValue: {
      type: Object,
      required: false,
      default: () => {},
    },
    handleUserSelectData: {
      type: Function,
      required: false,
      default: () => function () {},
    },
  },
  methods: {
    userSelect(date) {
      this.selectedSlot = {
        data: date.date.translatedDate,
        time: date.slot.time,
      };
    },
  },
};
</script>

<style scoped></style>
